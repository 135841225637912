import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "page-metadata"
    }}>{`Page Metadata`}</h1>
    <h2 {...{
      "id": "runtime-metadata"
    }}>{`Runtime metadata`}</h2>
    <p>{`Setting the page metadata is handled by `}<a parentName="p" {...{
        "href": "https://github.com/staylor/react-helmet-async"
      }}><inlineCode parentName="a">{`react-helmet-async`}</inlineCode></a>{`.
There is no need to setup it, simply start using it!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript",
        "metastring": "jsx",
        "jsx": true
      }}>{`// pages/home/home.view.tsx

import * as React from 'react';
import { Helmet } from 'react-helmet-async';

export default function HomeView() {
  return (
    <>
      <Helmet>
        <title>HomePage | MyCoolSite</title>
      </Helmet>

      <p>Welcome to my cool site</p>
    </>
  );
}
`}</code></pre>
    <p>{`We recommend you place the default Helmet configuration inside of `}<a parentName="p" {...{
        "href": "advanced-topics/root-component.md"
      }}>{`the Root component`}</a></p>
    <h2 {...{
      "id": "static-metadata"
    }}>{`Static metadata`}</h2>
    <p>{`Sometimes, you need to send metadata even if JavaScript is disabled, or before any JavaScript executes.`}</p>
    <p>{`If you use Server-Side-Rendering, the HTML sent by the server will contain all the metadata provided by Helmet.
If you don't use SSR, you can still send some metadata by modifying the base `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` file.`}</p>
    <p><em parentName="p">{`Note`}</em>{`: Without SSR, `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` is built only once and used for all pages. With SSR, it is rebuilt for each page.`}</p>
    <p><em parentName="p">{`Setup`}</em>{`:`}</p>
    <p>{`In the following example, we're adding Google Tag Manager on all pages`}</p>
    <p>{`First you need to tell the framework to `}<a parentName="p" {...{
        "href": "https://www.reworkjs.com/configuration#render-html"
      }}>{`use a custom html file generator`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json5"
      }}>{`// .reworkrc
{
  "render-html": "./src/render-html.js"
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/render-html.js

// note: this file is loaded by the builder itself, it needs to be valid JavaScript for your current version of Node.
// "data" contains the metadata generated by helmet & the framework
module.exports = function renderHtml(data) {

  return \`
<!DOCTYPE html>
<html \${data.htmlAttributes}>
<head>
  \${data.head}

  <!-- Google Tag Manager -->
  <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','xxx');</script>
  <!-- End Google Tag Manager -->
</head>
<body \${data.bodyAttributes}>
  <!-- Google Tag Manager (noscript) -->
  <noscript>
    <iframe
      src="https://www.googletagmanager.com/ns.html?id=xxx"
      height="0"
      width="0"
      style="display:none;visibility:hidden"
    ></iframe>
  </noscript>
  <!-- End Google Tag Manager (noscript) -->

  \${data.body}
</body>
</html>
  \`;
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      